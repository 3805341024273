<template>
  <div class="avatargroup f_flex f_a_c f_j_sa f_w" @click.stop="go">
    <div 
      class="f_flex f_a_c f_j_c f_s0 avatarItem" 
      :style="{...imgStyle}" 
      v-for='(item, index) in defaultAvatar' 
      :key='index'>
      <img style="width: 100%; height: 100%; border-radius: 4px" :src="item" alt="">
    </div>
  </div>
</template>

<script>
import menuGroupIcon from '@/assets/menu_group.png'
export default {
  data: ()=> ({}),
  props: {
    avatarList: {
      type: Array,
      default: ()=> ([])
    }
  },
  computed: {
    // 一排几个
    rowCount() {
      const len = this.avatarList.length
      if (len < 2) {
        return 1
      } else if (len < 5) {
        return 2
      } else {
        return 3
      }
    },
    imgStyle() {
      const len = this.rowCount
      const width = Math.floor((42-len)/len)
      return {
        width: `${width}px`,
        height: `${width}px`,
      }
    },
    defaultAvatar() {
      if (this.avatarList.length) {
        return this.avatarList
      } else {
        return [menuGroupIcon]
      }
    }
  },
  methods: {
    go() {
      this.$emit('change')
    }
  }
}
</script>

<style lang="less" scoped>
.avatargroup {
  width: 44px;
  height: 44px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
}
</style>
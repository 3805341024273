<template>
    <page :styleWrap="{background: '#fff'}">
    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div class="topInfo">

          <div >
            <van-cell icon="shop-o" v-for='(ii, key) in groupShowSettings' :key='key'>
                <template #title>
                    <span class="custom-title">{{ii.groupName}}</span>
                    
                </template>
                <template #icon>
                     <group-avatar :avatarList='[ii.groupAvatar]'></group-avatar>
                </template>

                <template #right-icon>
                    <my-switch v-model="ii.checkStatus" size="24px" @change="changeSettingStatus($event, ii.id)" />
                </template>
            </van-cell>
          </div>
          
        
      </div>

   
    </div>
  </page>
</template>

<script>

import groupApi from '@/api/group'
import groupAvatar from '@/components/groupAvatar/index.vue'
import mySwitch from '@/components/mySwitch/index.vue'

export default {
    components: {
    groupAvatar,
     mySwitch,
    },
    data: () => ({
        groupShowSettings: [],
    }),
    computed: {
       
    },
    methods: {

       changeSettingStatus(status, id) {
           for (var s in this.groupShowSettings) {
               var ss = this.groupShowSettings[s];
               if (ss.id == id) {
                 ss.showStatus = status ? 0 : 1
               } 
            }
            
            groupApi.groupDynamicSet ({ 
               groupShowSettings: this.groupShowSettings
            }).then (res => {
                console.log(res)
            })
       },
        getInfo () {
            groupApi.groupDynamicSetList ({
                companyId: this.$route.query.companyId
            }).then (res => {
                if (res.data) {
                    this.groupShowSettings = res.data.list
                    for (var s in this.groupShowSettings) {
                        this.groupShowSettings[s].checkStatus = this.groupShowSettings[s].showStatus == 0;
                    }
                }
            })
        },
        
    },
    mounted () {
      this.getInfo()
      // this.$route.meta.change = ()=> {
      //   this.getInfo()
        
      // }
        
    }
}
</script>

<style lang="less" scoped>
.custom-title {
    margin-left: 12px;
    vertical-align: middle;
    line-height: 41px;
  }

  .search-icon {
    font-size: 16px;
    line-height: inherit;
  }
</style>
